<template>
    <img
        :src="contacts.logo_path"
        alt="logo"
        :class="`${props.class ? props.class : 'tw-h-12 tw-w-12'}`"
        :width="width"
        :height="height"
        v-if="contacts && contacts.logo_path !== ''"
    />
    <img
        :src="src ?? '/img/logo.svg'"
        :class="`${props.class ? props.class : 'tw-h-12 tw-w-36'}`"
        :width="width"
        :height="height"
        alt="Logo"
        v-else
    />
</template>

<script setup lang="ts">
import { useContactsStore } from "~/stores/contactsStore"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    height?: string
    width?: string
    class?: string
    src?: string
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const contactsStore = useContactsStore()
const { contacts } = storeToRefs(contactsStore)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>
